import { ReactElement } from 'react';
import {
    StyledChipContainer,
    StyledChipTitles,
    StyledChipDesc,
    RefreshButton,
    StyledChipTextWrapper,
    StyledChipDate,
} from './Chips.styles';
import { TChipProps } from './Chips.types';
import { Refresh } from 'src/shared/images/icons/Refresh';
import CustomThemeProvider from '@/common/app/contexts/ThemeContext';

export const Chips = ({
    title,
    description,
    date,
    onClick,
    ...props
}: TChipProps): ReactElement => {
    return (
        <CustomThemeProvider>
            <StyledChipContainer data-testid="chips" onClick={onClick} {...props}>
                <RefreshButton>
                    <Refresh />
                </RefreshButton>
                <StyledChipTextWrapper>
                    <StyledChipTitles>{title}</StyledChipTitles>
                    <StyledChipDesc>{description}</StyledChipDesc>
                </StyledChipTextWrapper>
                <StyledChipDate>{date}</StyledChipDate>
            </StyledChipContainer>
        </CustomThemeProvider>
    );
};

export default Chips;
