import { Container } from '@/common/ui/containers/Container';
import { createStructuredBreadcrumbList } from '@/entities/Activity/app/Creators';
import { ReactElement, memo } from 'react';
import { IBreadcrumbsItem } from '../domain/Breadcrumbs.domain';
import * as S from './Breadcrumbs.styles';

type BreadcrumbsProps = {
    breadcrumbs: IBreadcrumbsItem[];
};

export const BreadcrumbsContainer = ({ breadcrumbs }: BreadcrumbsProps): ReactElement => {
    const breadcrumbsStructuredData = createStructuredBreadcrumbList(breadcrumbs);

    return (
        <S.Wrapper data-test-id="Breadcrumbs">
            <Container>
                <S.Content>
                    {breadcrumbs.map((item, index) => {
                        const isLast = index === breadcrumbs.length - 1;
                        const isPrev = index === breadcrumbs.length - 2;

                        return (
                            <S.BreadcrumbContainer key={item.title}>
                                {item.url && !item.unlink ? (
                                    <S.StyledLink
                                        href={item.url}
                                        passHref
                                        prefetch={false}
                                        isLast={isLast}
                                    >
                                        {item.title}
                                    </S.StyledLink>
                                ) : (
                                    <S.WithoutLink isLast={isLast}>{item.title}</S.WithoutLink>
                                )}
                                <S.ArrowContainer isLast={isLast}>
                                    <S.Arrow isPrev={isPrev} />
                                </S.ArrowContainer>
                            </S.BreadcrumbContainer>
                        );
                    })}
                </S.Content>
                {breadcrumbsStructuredData && (
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: breadcrumbsStructuredData }}
                    />
                )}
            </Container>
        </S.Wrapper>
    );
};

export const Breadcrumbs = memo(BreadcrumbsContainer);
