import { ReactElement, useMemo } from 'react';
import {
    StyledAmount,
    StyledCurrentSymbol,
    StyledValue,
    StyledDollar,
    StyledCentWrapper,
    StyledSeparator,
    StyledCent,
} from './Amount.styles';
import { TAmountProps } from './Amount.types';

const ZERO_MINOR_PART_REGEXP = /^0+$/;

const format = (value: string | null) => {
    const cent = (value: string | null) => {
        const result = value != null ? Math.abs(parseInt(value) % 100) : 0;
        if (result < 10) {
            return `0${result}`;
        }
        return result;
    };
    const dollar = (value: string | null) =>
        value != null ? Math.trunc(parseInt(value) / 100) : 0;
    const valueToString = (value: string | null) => `${dollar(value)}.${cent(value)}`;

    return {
        dollars: dollar(value),
        cents: cent(value),
        value: valueToString(value),
    };
};

const Amount = ({ styles, units, ...props }: TAmountProps): ReactElement => {
    const { dollars, cents } = useMemo(() => format(units), [units]);
    const showMinor = cents > 0 && !ZERO_MINOR_PART_REGEXP.test(cents.toString());
    return (
        <StyledAmount data-testid="Amount" styles={styles} {...props}>
            <StyledValue data-testid="value">
                <StyledCurrentSymbol data-testid="currencySymbol">{'\u0024'}</StyledCurrentSymbol>
                <StyledDollar data-test-id="major">{dollars}</StyledDollar>
                {showMinor && (
                    <StyledCentWrapper>
                        <StyledSeparator data-test-id="separator">.</StyledSeparator>
                        <StyledCent data-test-id="minor">{cents}</StyledCent>
                    </StyledCentWrapper>
                )}
            </StyledValue>
        </StyledAmount>
    );
};

export default Amount;
