import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import placeHolderImage from '@/shared/images/Images/placeHolderImage.jpg';
import { radius, borders } from '@/styles/Themes/Metrics';
import Link from 'next/link';

export const Container = styled.div`
    box-shadow: ${cssVars.shadow_card_default};
    border-radius: ${radius.small};
    margin: 25px auto;
    background-color: ${cssVars.bg_white_dark3};
    padding: 12px 12px 25px;

    ${Screen.desktop} {
        display: flex;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        background-color: none;
    }
`;

export const VoucherInfoWrapper = styled.div`
    ${Screen.desktop} {
        height: 275px;
        width: 334px;
        padding: 26px 28px;
        margin: 0 0 0 24px;
        border-radius: ${radius.small};
        background: ${cssVars.bg_ash_dark2};
    }
`;

export const VoucherInfoTitle = styled(Link)`
    display: flex;
    margin: 13px 0 10px;
    ${Text.Mobile.large.semibold};
    color: ${cssVars.fg_gray1_white};

    ${Screen.desktop} {
        ${Text.Desktop.large.semibold};
    }
`;

export const VoucherInfoImage = styled.img`
    background-image: url(${placeHolderImage.src});
    width: 100%;
    height: 177px;
    border-radius: ${radius.small};
    object-fit: cover;

    ${Screen.ipad} {
        height: 218px;
    }

    ${Screen.desktop} {
        max-width: 564px;
        height: 275px;
    }
`;

export const VoucherInfoDate = styled.div`
    display: flex;
    align-items: center;
    color: ${cssVars.fg_gray2_gray3};
    margin: 0 0 19px;

    ${Screen.desktop} {
        ${Text.Desktop.normal.regular};
        margin: 0;
        padding-bottom: 29px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${Screen.desktop} {
        margin-bottom: 30px;
        border-bottom: ${borders.small} ${cssVars.bg_gray4_dark1};
    }
`;
