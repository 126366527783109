import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { radius } from 'src/styles/Themes/Metrics';
import { Buy } from '@/shared/images/icons/Buy';
import GiftCardPromoMobile from '@/shared/images/Images/GiftCardPromo.png';
import GiftCardPromoDesktop from '@/shared/images/Images/GiftCardPromoDesktop.png';
import GiftCardPromoTablet from '@/shared/images/Images/GiftCardPromoIpad.png';
import { CheckSolid } from '@/shared/images/icons/CheckSolid';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { borders } from '@/styles/Themes/Metrics';
import Link from 'next/link';

export const Wrapper = styled.div`
    background-color: ${cssVars.bg_white_blue};
    width: 100%;
    padding: 0 18px 25px;

    ${Screen.ipad} {
        padding: 0 30px 30px;
    }

    ${Screen.desktop} {
        padding: 0 30px;
    }
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 44px 20px 50px;
    max-width: 1064px;
    border-radius: ${radius.small};

    background-color: ${cssVars.bg_ash_dark3};
    background-image: url(${GiftCardPromoMobile.src});
    background-repeat: no-repeat;
    background-position: center bottom 120px;

    ${Screen.ipad} {
        background-image: url(${GiftCardPromoTablet.src});
        background-position: left 130px bottom 72px;
        padding: 64px 30px 60px;
    }
    ${Screen.desktop} {
        background-image: url(${GiftCardPromoDesktop.src});
        background-position: bottom -15px left 520px;
        padding: 52px 55px 60px;
        margin: 0 auto;
    }
`;

export const BuyStripe = styled(Buy)`
    position: absolute;
    top: 0;
    right: 20px;
    width: 40px;
    height: 50px;
    ${css`
        ${Screen.ipad} {
            right: 26px;
            width: 48px;
            height: 60px;
        }
        ${Screen.desktop} {
            right: 47px;
            width: 54px;
            height: 68px;
        }
    `}
`;

export const Title = styled.h3`
    display: block;
    color: ${cssVars.fg_gray1_white};
    margin: 0 0 10px;
    max-width: 254px;
    ${Text.Full.heading2};

    ${Screen.ipad} {
        max-width: 324px;
    }
    ${Screen.desktop} {
        max-width: 464px;
        height: 35px;
    }
`;

export const Item = styled.div`
    color: ${cssVars.fg_gray2_gray3};
    margin: 7px 0 0 4px;
    display: flex;
    align-items: center;
    ${Text.Full.medium.regular}

    ${Screen.desktop} {
        margin: 8px 0 0 5px;
        ${Text.Desktop.large.regular}
    }
`;

export const Checkbox = styled(CheckSolid)`
    width: 12px;
    height: 8px;
    margin-right: 8px;
    color: ${Palette.green.normal};
    ${css`
        ${Screen.ipad} {
            width: 14px;
            height: 10px;
        }
    `}
`;

export const BtnWrap = styled.div`
    margin-top: 219px;
    ${Screen.ipad} {
        margin-top: 260px;
    }
    ${Screen.desktop} {
        display: flex;
        justify-content: space-between;
        margin: 35px 20px 0 0;
        display: flex;
        align-items: baseline;
        max-width: 440px;
    }
`;

export const Button = styled(Link)`
    color: ${Palette.green.normal};
    background-color: transparent;
    border: ${borders.small} ${Palette.green.normal};
    border-radius: ${radius.large};
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${Text.Mobile.medium.medium}

    :active,
    :hover {
        border: ${borders.small} ${Palette.green.light};
    }

    ${Screen.ipad} {
        height: 55px;
        ${Text.Ipad.medium.medium}
    }
    ${Screen.desktop} {
        ${Text.Desktop.large.medium}
        width: 50%;
        height: 50px;
    }
`;
