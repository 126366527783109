import { Item } from './LinkItem.styles';

export const LinkItem = ({
    href,
    name,
    onClick,
    numItems,
}: {
    href: string;
    name: string;
    onClick: () => void;
    numItems?: number;
}) => {
    return (
        <Item href={href} passHref numItems={numItems} onClick={onClick}>
            {name}
        </Item>
    );
};
