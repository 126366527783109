import { ReactElement } from 'react';
import { StyledText } from './Text.styles';
import { TTextProps } from './Text.types';

const Text = ({ children, ...props }: TTextProps): ReactElement => {
    return (
        <StyledText data-testid="text" {...props}>
            {children}
        </StyledText>
    );
};

export default Text;
