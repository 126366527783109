import { BreadcrumbsArrowRight } from '@/shared/images/icons/BreadcrumbsArrowRight';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import Link from 'next/link';

export const Wrapper = styled.div`
    margin: 27px 0 24px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        right: 16px;
        top: 0;
        background-image: linear-gradient(to left, transparent 0%, ${cssVars.bg_white_blue} 100%);
        background-image: -webkit-linear-gradient(
            0deg,
            ${cssVars.transparent_white_blue} 0%,
            ${cssVars.bg_white_blue} 100%
        );
        width: 8px;
        height: 16px;
        z-index: 1;
        opacity: 0.9;
    }

    ${Screen.ipad} {
        margin: 37px 0 34px;

        &:after {
            right: 28px;
        }
    }
`;

export const Content = styled.div`
    overflow-x: auto;
    white-space: pre;
    height: 26px;

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${cssVars.bg_ash_blueDark};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        height: 30px;
        background: ${Palette.gray.gray3};
        border-radius: 10px;
    }
`;

export const BreadcrumbContainer = styled.div`
    margin: 0;
    display: inline;
`;

export const StyledLink = styled(Link)<{ isLast: boolean }>`
    color: ${({ isLast }) => (isLast ? `${Palette.gray.normal}` : `${Palette.gray.gray3}`)};
    ${Text.Full.medium.medium}
    display: inline;
    &:hover {
        text-decoration: underline;
        color: ${Palette.green.normal};
    }
`;

export const WithoutLink = styled.span<{ isLast: boolean }>`
    color: ${({ isLast }) => (isLast ? `${Palette.gray.normal}` : `${Palette.gray.gray3}`)};
    ${({ isLast }) => !!isLast && `margin-right: 8px;`};
    ${Text.Full.medium.medium}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

// export const ThingsToDoLink = styled(Link)`
//     color: ${Palette.gray.gray3};
//     ${Text.Full.large.regular}
//     min-width: 88px;
//     &:hover {
//         text-decoration: underline;
//         color: ${Palette.green.normal};
//     }
// `;

export const CategoryLink = styled.span`
    display: none;
    ${Screen.desktop} {
        display: flex;
        color: ${Palette.gray.gray3};
        ${Text.Full.large.medium}
    }
`;

export const ArrowContainer = styled.div<{ isLast?: boolean }>`
    display: ${({ isLast }) => (isLast ? 'none' : 'inline')};
    align-items: center;
    padding: 0 5px;
`;

export const Arrow = styled(BreadcrumbsArrowRight)<{ isPrev?: boolean }>`
    align-self: center;
    transform: rotate(-90deg);
    color: ${({ isPrev }) => (isPrev ? cssVars.bg_ash_normal : cssVars.bg_blueDark_blue)};
`;
