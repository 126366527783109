import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import styled, { css, DefaultTheme } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';

import { Transition } from '@/styles/Themes/KeyFrames';
import Link from 'next/link';

interface ILinkItem extends DefaultTheme {
    numItems?: number;
}

export const Item = styled(Link)<ILinkItem>`
    color: ${cssVars.fg_gray1_white};
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 18px;
    height: 40px;

    &:hover {
        background-color: ${cssVars.bg_ash_blueDark};
        border-radius: ${radius.medium};
        transition: ${Transition.normal};
    }

    ${({ numItems }) =>
        numItems &&
        css`
            &::after {
                content: '${numItems}';
                position: absolute;
                right: 18px;
                top: 11px;
                background-color: ${Palette.green.normal};
                color: ${Palette.white.normal};
                border-radius: ${radius.medium};
                ${Text.Full.normal.medium}
                padding: 0 7px;
            }
        `}

    ${Screen.ipad} {
        height: 50px;
    }
    ${Screen.desktop} {
        height: 45px;
    }
`;
