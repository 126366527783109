import { ReactElement } from 'react';
import { StyledParagraph } from './Paragraph.styles';
import { TParagraphProps } from './Paragraph.types';

const Paragraph = ({ styles, children, ...props }: TParagraphProps): ReactElement => {
    return (
        <StyledParagraph data-testid="paragraph" styles={styles} {...props}>
            {children}
        </StyledParagraph>
    );
};

export default Paragraph;
