import { ReactElement, useState, useCallback, useRef } from 'react';

import * as S from './DropdownLink.styles';
import { useOnClickOutside } from '@/common/service/hooks';

export type DropdownLinkItem = {
    name: string;
    href: string;
    subtitle?: string;
};

export type DropdownLinkProps = {
    list: DropdownLinkItem[];
    title: string;
};

export const DropdownLink = ({ list, title }: DropdownLinkProps): ReactElement => {
    const [isActive, setIsActive] = useState(false);
    const refContainer = useRef(null);

    const handleClickOutside = useCallback(() => {
        setIsActive(false);
    }, []);

    useOnClickOutside(refContainer, handleClickOutside);

    return (
        <S.Wrapper>
            <S.Container
                isActive={isActive}
                ref={refContainer}
                onClick={() => setIsActive(!isActive)}
            >
                <S.Title>
                    {title}
                    <S.Arrow isActive={isActive} />
                </S.Title>
                {isActive && (
                    <S.Animation>
                        {list.map(({ name, href, subtitle }) => (
                            <S.Item key={name} href={href.replaceAll(' ', '-')} passHref>
                                {name}
                                {subtitle && <S.Code>{subtitle}</S.Code>}
                            </S.Item>
                        ))}
                    </S.Animation>
                )}
            </S.Container>
        </S.Wrapper>
    );
};
