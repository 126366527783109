import { ReactElement, useEffect, useRef, useState } from 'react';
import * as S from './SideMenu.styles';
import { SideMenuProps } from './SideMenu.types';
import useOnClickOutside from '../../service/UseOutsideClick';
import { useThemeContext } from '@/common/app/contexts/ThemeContext';
import { getCS } from '@/common/service/storage';
import { TCommonTypeActivityProps } from '@/shared/FeaturedActivities/domain/FeaturedActivities.domain';
import { Router, useRouter } from 'next/router';
import URL from '@/common/app/utils/urlsUtils';
import { LinkItem } from './sideMenu/LinkItem';
import Link from 'next/link';

export const SideMenu = ({
    onChangeOpen,
    onTalkToUs,
    open,
    setOpen,
}: SideMenuProps): ReactElement => {
    const { toggleTheme, theme } = useThemeContext();
    const isDark = theme.name === 'dark';
    const ref = useRef<HTMLDivElement>(null);

    const [touchStartX, setTouchStartX] = useState(0);
    const [touchStartY, setTouchStartY] = useState(0);
    const [recentViewed, setRecentViewed] = useState<TCommonTypeActivityProps[]>([]);

    useEffect(() => {
        const json = getCS('Recently_Viewed');

        if (json && Array.isArray(json)) {
            setRecentViewed(
                json.filter(
                    (activity) =>
                        activity &&
                        typeof activity === 'object' &&
                        typeof activity.name === 'string'
                )
            );
        }
    }, []);

    const { pathname } = useRouter();
    const notCheckoutPage = pathname !== '/checkout';

    const hideMenu = () => {
        if (ref.current) {
            ref.current.style.transform = 'translateX(-100%)';
        }
    };

    const handleClickOutside = () => {
        hideMenu();

        setTimeout(() => {
            onChangeOpen(false);
        }, 300);
    };

    const scrollToRecent = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 550,
                behavior: 'smooth',
            });
            Router.events.off('routeChangeComplete', scrollToRecent);
        }, 300);
    };

    const handleClickRecent = () => {
        hideMenu();

        setTimeout(() => {
            onChangeOpen(false);
            Router.events.on('routeChangeComplete', scrollToRecent);
        }, 300);
    };

    const openTalkToUs = () => {
        hideMenu();

        setTimeout(() => {
            onChangeOpen(false);
            onTalkToUs();
        }, 300);
    };

    useOnClickOutside(ref, handleClickOutside);

    const stoped = (e: React.TouchEvent<HTMLDivElement>) => {
        const diffX = touchStartX - e.changedTouches[0].clientX;
        const diffY = touchStartY - e.changedTouches[0].clientY;
        if (diffX > diffY && diffX > 40) {
            setTouchStartX(0);
            setTouchStartY(0);
            handleClickOutside();
        }
    };

    return (
        <S.StyleSideMenuWrapper
            ref={ref}
            onTouchStart={(e) => {
                setTouchStartX(e.changedTouches[0].clientX);
                setTouchStartY(e.changedTouches[0].clientY);
            }}
            onTouchEnd={stoped}
            data-test-id="Sidemenu"
        >
            <S.IconsWrapper>
                <Link href="/" passHref>
                    <S.Logotype />
                </Link>
                <div onClick={handleClickOutside}>
                    <S.ArrowButton />
                </div>
            </S.IconsWrapper>
            <S.LinksWrapper>
                <LinkItem name="Explore" href="/destination/" onClick={handleClickOutside} />
                {Boolean(recentViewed.length) && (
                    <LinkItem name="Recently Viewed" href="/" onClick={handleClickRecent} />
                )}
                <LinkItem name="Wish List" href="/wishlist/" onClick={handleClickOutside} />
                <LinkItem name="Landmark Guides" href="/landmarks/" onClick={handleClickOutside} />
                <LinkItem
                    name="Traveler Stories"
                    href="/traveler-stories/"
                    onClick={handleClickOutside}
                />
                <LinkItem name="Blog" href="/blog/" onClick={handleClickOutside} />
                <LinkItem name="Tour Partners" href="/partners/" onClick={handleClickOutside} />
            </S.LinksWrapper>
            <S.LinksWrapper>
                <S.SwipeButton />
                <S.LocalSuportItems
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openTalkToUs();
                    }}
                    href={'#'}
                >
                    <S.TalkToUsIcon />
                    Talk to us
                </S.LocalSuportItems>
                <S.LocalSuportItems
                    href={'#'}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleClickOutside();
                        setOpen(!open);
                    }}
                >
                    <S.ChatIcon />
                    Chat with us
                </S.LocalSuportItems>
                <S.LocalSuportItems
                    href="/customer-service-guide/"
                    passHref
                    onClick={handleClickOutside}
                >
                    <S.HelpIcon />
                    Help
                </S.LocalSuportItems>
            </S.LinksWrapper>
            {notCheckoutPage && (
                <S.ColorTheme onClick={toggleTheme}>
                    Dark mode:&nbsp;
                    {isDark ? 'on' : 'off'}&nbsp;
                    <S.Toggle isDark={isDark}>
                        {isDark ? <S.SwitchToLight /> : <S.SwitchToDark />}
                    </S.Toggle>
                </S.ColorTheme>
            )}
            <S.Bottom>
                <S.ListLink href={`${URL.partners}/activity-operators/`} passHref>
                    List your tour
                </S.ListLink>
            </S.Bottom>
        </S.StyleSideMenuWrapper>
    );
};
